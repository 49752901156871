import '../../styles/cv/header.scss'

function Header() {
    return(
        <header>
            <p>qui_je_suis.exe</p>

            <ul>
                <li>edit |</li>
                <li>back</li>
            </ul>
        </header>
    )
}

export default Header